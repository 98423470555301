import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  EditIcon,
  NextIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerDocumentUploadForm,
  ReviewDocumentForm,
  ReviewFundingDocumentForm,
} from '@/forms';
import { DocumentStatus, DocumentType } from '@/globalTypes';
import { getDocumentTypeName } from '@/utils/document-types';
import { assert } from '@/utils/error';
import formatDate from '@/utils/formatter/formatDate';
import { PlayerDocuments_player_documents_edges_node } from './__generated__/PlayerDocuments';
import { SanityPlayerDocumentsBlockStaticQuery } from './__generated__/SanityPlayerDocumentsBlockStaticQuery';
import usePlayerDocuments from './usePlayerDocuments';

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerDocumentsBlockStaticQuery {
    sanityPlayerDocumentsBlock {
      title {
        ...LocaleString
      }
    }
  }
`;

const fundingDocumentTypesMap: Record<string, true> = {
  DividendsSOW: true,
  InheritanceSOW: true,
  SOW: true,
  SalarySOW: true,
  SaleOfCompanySOW: true,
  SaleOfPropertySOW: true,
  SourceOfWealth: true,
};

const isFundingDocument = (
  doc: PlayerDocuments_player_documents_edges_node,
) => {
  return !!(doc.documentType && fundingDocumentTypesMap[doc.documentType]);
};

const PlayerDocumentsBlock: FC = () => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const block =
    useStaticQuery<SanityPlayerDocumentsBlockStaticQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityPlayerDocumentsBlock;

  const {
    playerDocuments,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerDocuments(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerDocuments',
    data: playerDocuments,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'id',
        title: 'Id',
      }),
      s.dateTimeValue({
        field: 'uploadedAt',
        title: 'Uploaded At',
        getValue: ({ row }) => formatDate(row.uploadedAt),
      }),
      s.actionValue({
        field: 'actions',
        title: 'Actions',
        getValue: ({ row }) =>
          row.status !== DocumentStatus.Deleted && (
            <ControlledModal
              content={
                isFundingDocument(row) ? (
                  <ReviewFundingDocumentForm
                    document={row}
                    playerId={params.playerId}
                  />
                ) : (
                  <ReviewDocumentForm document={row} />
                )
              }
            >
              <InlineIconButton>
                <EditIcon />
              </InlineIconButton>
            </ControlledModal>
          ),
      }),
      s.enumValue({
        field: 'status',
        title: 'Status',
        e: DocumentStatus,
        getValue: ({ row }) => (
          <>
            {row.status === DocumentStatus.Approved && '✅ '}
            {row.status === DocumentStatus.Rejected && '❌ '}
            {row.status === DocumentStatus.Pending && '⏳ '}
            {row.status === DocumentStatus.Deleted && '🗑 '}
            {row.status}
          </>
        ),
      }),
      s.initiatorValue({
        field: 'uploadedBy',
        title: 'Uploaded By',
      }),
      s.initiatorValue({
        field: 'verifiedBy',
        title: 'Verified By',
      }),
      s.enumValue({
        field: 'documentType',
        title: 'Document Type',
        e: DocumentType,
        getValue: ({ row }) =>
          row.documentType && getDocumentTypeName(row.documentType),
      }),
      s.stringValue({
        field: 'verificationTypes',
        title: 'Verification Types',
        getValue: ({ row }) => row.verificationTypes.join(', ') || '-',
      }),
      s.stringValue({
        field: 'expirationDate',
        title: 'Expiration Date',
        getValue: ({ row }) => formatDate(row.expirationDate, true),
      }),
      s.dateTimeValue({
        field: 'deletedAt',
        title: 'Deleted At',
      }),
      s.initiatorValue({
        field: 'deletedBy',
        title: 'Deleted By',
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />

          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal
            content={<PlayerDocumentUploadForm playerId={params.playerId} />}
          >
            <CardOptionsButton>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerDocumentsBlock;
